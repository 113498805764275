import {type SubmissionHandler, useForm} from 'vee-validate';
import * as Yup from 'yup';
import {EAuthSubscriptionType, type TRegisterUser} from "../model/types";
import useAuthService from "../api/service";
import {useMutation} from "@tanstack/vue-query";
import type {AxiosError} from "axios";
import type {TServerResponse} from "@frontend_entities/app";
import {useRoute, useRouter} from "vue-router";
import {ref, type Ref, toValue} from "vue";
import {scrollToError} from "@frontend_shared/lib/utils";
import {StorageSerializers, useLocalStorage, useUrlSearchParams} from "@vueuse/core";
import {useCookies} from "@vueuse/integrations/useCookies";

const schema = Yup.object({
    email: Yup.string().email('Please enter valid email').required('The email field is required'),
    phone: Yup.string().matches(/[0-9\+]{9,16}/, 'The Phone field format is invalid').required('The Phone field is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('The password field is required'),
});

export const useRegisterForm = () => {
    const AuthService = useAuthService();
    const route = useRoute();
    const form: Ref<TRegisterUser> = ref({
        email: '',
        phone: '',
        password: '',
        invitationCode: '',
        type: route.query.type as unknown as EAuthSubscriptionType ?? EAuthSubscriptionType.DEMO,
    });
    const router = useRouter();
    const params = useUrlSearchParams();

    const cookies = useCookies();

    const saveUTMToStorage = () => {
        const utmKeys: string[] = ['utm_medium', 'utm_campaign', 'utm_content', 'utm_source'];
        let utmParams = {};
        Object.keys(params).forEach(key => {
            if(utmKeys.includes(key.toLowerCase())) {
                utmParams[key.toLowerCase()] = params[key];
            }
        });

        if(Object.keys(utmParams).length > 0){
            cookies.set('ojv_utm_params', JSON.stringify(utmParams), {expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)});
        }
    }

    saveUTMToStorage();

    const {errors, setErrors, defineField, handleSubmit} = useForm<TRegisterUser>({
        initialValues: form.value,
        validationSchema: schema,
    });

    const {mutate, isPending} = useMutation({
        mutationFn: (payload: TRegisterUser) => AuthService.register(payload),
        async onSuccess(data, variables, context) {
            await router.push(route.query?.redirect ? {path: route.query.redirect as string} : {name: 'home'});
        },
        onError(error: AxiosError, variables, context) {
            setErrors((error?.response?.data as TServerResponse)?.errors as any);
        },
    });

    const submit: SubmissionHandler = handleSubmit((values) => {
        values['utm'] = cookies.get('ojv_utm_params') ?? null;
        mutate(values);
    }, () => scrollToError()) as unknown as SubmissionHandler;

    return {form, errors, isPending, schema, submit}
};
