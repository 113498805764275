import type {RouteLocationRaw, RouteRecordRaw} from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router';
import {ERouteGuard, type FrontendRouteRecordType, type RouteToType} from "@frontend_shared/router/types";
import {loadLayoutMiddleware} from "@frontend_app/router/middleware/loadLayout.middleware";
import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {useLocaleStore} from "@frontend_entities/locale";
import {componentRoutes} from "@frontend_app/router/entities/componentRoutes";
import {companyRoutes} from "@frontend_app/router/entities/companyRoutes";
import {livelyPhotoRoutes} from "@frontend_app/router/entities/livelyPhotoRoutes";
import {cardRoutes} from "@frontend_app/router/entities/cardRoutes";
import {leafletRoutes} from "@frontend_app/router/entities/leafletRoutes";
import {calendarRoutes} from "@frontend_app/router/entities/calendarRoutes";
import {useAuthStore} from "@frontend_entities/auth/model/store";
import {useAppService} from "@frontend_entities/app";
import {EUserRole} from "@frontend_entities/user";
import {authRoutes} from "@frontend_app/router/entities/authRoutes";
import {myOrderRoutes} from "@frontend_app/router/entities/myOrderRoutes";
import {paymentTransactionRoutes} from "@frontend_app/router/entities/paymentTransactionRoutes";
import {selfLivingRoutes} from "@frontend_app/router/entities/selfLivingRoutes";
import {storeToRefs} from "pinia";
import {getRedirectQuery} from "@frontend_shared/lib/utils";
import {toBookRoutes} from "@frontend_app/router/entities/toBookRoutes";
import {ref} from "vue";
import type {EPermission} from "@frontend_shared/app/types";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("@frontend_pages/Home.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Augmented Reality Designer',
            guard: ERouteGuard.AUTHORISED
        }
    },
    ...authRoutes,
    ...toBookRoutes,
    ...livelyPhotoRoutes,
    ...cardRoutes,
    ...leafletRoutes,
    ...calendarRoutes,
    ...selfLivingRoutes,
    ...companyRoutes,
    ...componentRoutes,
    ...myOrderRoutes,
    ...paymentTransactionRoutes,
    {
        path: '/tariffs',
        name: 'tariffs.index',
        component: () => import("@frontend_pages/tariffs/TariffsIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Tariffs',
            guard: ERouteGuard.AUTHORISED
        }
    },
    {
        path: '/referral-program',
        name: 'referral-programs.index',
        component: () => import("@frontend_pages/referral-program/ReferralProgramIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Referral program',
            guard: ERouteGuard.AUTHORISED
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@frontend_pages/NotFound.vue'),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: '404 Page not found',
            guard: ERouteGuard.ANY
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];

export type RoutesType = typeof routes;

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {top: 0}
        }
    },
    routes: routes as unknown as RouteRecordRaw[]
})

router.beforeEach(loadLayoutMiddleware);

let isAppInitialized = ref(false);
const AppService = useAppService();

router.beforeResolve(async (to, from) => {
    const AuthStore = useAuthStore();
    const {isAuth, isDemoMode, isLockedMode, isSubscriptionPaymentType, hideTariffs} = storeToRefs(AuthStore);

    if(to.path === '/[object%20Object]'){
        return {name: 'home'};
    }

    if (!isAppInitialized.value) {
        try {
            await AppService.init();
            if (AuthStore.userHasRole([EUserRole.ADMIN]) || AuthStore.userHasPermission('admin.app.init')) {
                location.href = '/admin';
                return {name: 'auth.login'};
            } else if (isAuth.value && to.meta.guard === ERouteGuard.GUEST) {
                return from.query?.redirect ? {path: from.query.redirect as string} : {name: 'home'};
            } else if (!from.name && isAuth.value && to.meta.needSubscriptionOption && !AuthStore.userSubscriptionHasOption(to.meta.needSubscriptionOption)) {
                return {name: 'home'};
            } else if ((isDemoMode.value && !isSubscriptionPaymentType.value || hideTariffs.value) && ['tariffs.index'].includes(to.name as string)) {
                return {name: 'home'};
            } else if (isAuth.value && isLockedMode.value && !['orders.index', 'home', isSubscriptionPaymentType.value && !hideTariffs.value ? 'tariffs.index' : ''].includes(to.name as string)) {
                return {name: 'home'};
            }

            isAppInitialized.value = true;
        } catch (e) {
            isAppInitialized.value = true;
            if (to.meta.guard === ERouteGuard.AUTHORISED) {
                return {name: 'auth.login', query: {redirect: getRedirectQuery(to)}};
            }
        } finally {
            document.getElementById('app')?.classList?.remove('loading');
        }
    }

    if (from.name && to.meta.guard === ERouteGuard.AUTHORISED) {
        try {
            return isAuth.value ? true : {name: 'auth.login'};
        } catch (error) {
            throw error;
        }
    }
    return true;
})

router.beforeEach(async (to, from) => {
    const AuthStore = useAuthStore();

    const {isAuth, isLockedMode, isSubscriptionPaymentType, hideTariffs} = storeToRefs(AuthStore);

    if (isAuth.value && to.meta.needSubscriptionOption && !AuthStore.userSubscriptionHasOption(to.meta.needSubscriptionOption)) {
        if (!from.name) {
            return {name: 'home'};
        }
        return false;
    }

    if(hideTariffs.value && ['tariffs.index'].includes(to.name as string)){
        return {name: 'home'};
    }

    if (isAuth.value && isLockedMode.value) {
        if (isSubscriptionPaymentType.value && !['tariffs.index', 'orders.index'].includes(to.name as string)) {
            return {name: 'tariffs.index'};
        } else if (!['orders.index', 'home', isSubscriptionPaymentType.value ? 'tariffs.index' : ''].includes(to.name as string)) {
            return {name: 'home'};
        }
    }

    const {$t} = useLocaleStore();
    document.title = $t(to.meta.title ? to.meta.title as unknown as string : import.meta.env.VITE_APP_NAME);
});

export default router;

export function route(to: RouteToType): RouteLocationRaw {
    return to as RouteLocationRaw;
}
